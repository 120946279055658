import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import * as styles from './microsite.module.sass'

const Podcast = ({ img, imgLink, children }) => {
  return (
    <div className="has-text-centered container">
      <div className={`${styles.colenioPodcast}`}>
        <a href={imgLink}>
          <GatsbyImage
            image={getImage(img)}
            imgClassName="is-rounded"
            objectFit="cover"
            alt=""
          />
        </a>
      </div>
      <p className="padding-3">{children}</p>
    </div>
  )
}

export default Podcast
