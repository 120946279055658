import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import LargeHeroImageText from '../components/microsite/LargeHeroImageText'
import TilesBox from '../components/microsite/Tiles'
import ElementSplit from '../components/microsite/ElementSplit'
import ListComponent from '../components/microsite/ListComponent'
import CallToAction from '../components/microsite/CallToAction'
import { isMobile } from 'react-device-detect'
import CallToActionMobile from '../components/microsite/CallToActionMobile'
import Contact from '../components/microsite/Contact'
import { useRef } from 'react'
import Podcast from '../components/microsite/Podcast'

// TODO: Maybe add a reroute to this and give this file an english name again for consistent naming?

const potentialsTiles = [
  {
    icon: 'fa-unlock-alt',
    content: 'Durchführung einer permanenten Cyberrisiko Analyse.',
  },
  {
    icon: 'fa-file-alt',
    content:
      'Aufnahme der Cyberrisiko Analyse in die technische Dokumentation.',
  },
  {
    icon: 'fa-cogs',
    content:
      'Due Diligence Prüfung bei der Integration von Komponenten anderer Hersteller.',
  },
  {
    icon: 'fa-project-diagram',
    content: '5 Jahre Bereitstellung von Sicherheitsupdates.',
  },
  {
    icon: 'fa-tasks',
    content:
      'Implementierung geeigneter Richtlinien und Verfahren zur Offenlegung von Schwachstellen.',
  },
  {
    icon: 'fa-comment-dots',
    content: 'Einhaltung von Meldepflichten.',
  },
  {
    icon: 'fa-lightbulb',
    content:
      'Klare und verständliche Gebrauchsanweisungen für Produkte mit digitalen Elementen.',
  },
]

const colenioTiles = [
  {
    icon: 'fa-balance-scale-right',
    content:
      'Wir prüfen die Relevanz des Cyber Resilience Acts für ihr Unternehmen.',
  },
  {
    icon: 'fa-chart-line',
    content:
      'Wir führen eine intiale Cyberrisikoanalyse zur Standortbestimmung durch.',
  },
  {
    icon: 'fa-list-ol',
    content: 'Wir erarbeiten konkrete Handlungsempfehlungen zur Umsetzung.',
  },
  {
    icon: 'fa-people-arrows',
    content:
      'Wir begleiten und / oder unterstützen Sie bei der Umsetzung der Handlungsempfehlungen.',
  },
  {
    icon: 'fa-project-diagram',
    content:
      'Wir erarbeiten mit ihnen geeignete Prozesse (Meldung, Updates, Risikoanalyse).',
  },
  {
    icon: 'fa-binoculars',
    content:
      'Wir begleiten Sie bei der permanenten Überwachung & Analyse von Cyberrisiken.',
  },
  {
    icon: 'fa-handshake',
    content:
      'Wir übernehmen für Sie die kompletten Prozesse und sorgen für den sicheren Betrieb ihrer Produkte.',
  },
]

const challenges = [
  {
    icon: 'fa-chart-line',
    title: 'Risikoanalyse',
    content:
      'Etablierung einer permanenten Risikoanalyse von der Designphase über die gesamte Lebensdauer der Produkte.',
  },
  {
    icon: 'fa-comment-dots',
    title: 'Meldeprozesse',
    content:
      'Etablierung geeigneter Prozesse zur Meldung von Cybersicherheits - Vorfällen.',
  },
  {
    icon: 'fa-project-diagram',
    title: 'Updateprozesse',
    content: 'Impelentierung von Update - Prozessen für Sicherheitsupdates.',
  },
  {
    icon: 'fa-lock',
    title: 'Umsetzung',
    content:
      'Schließen von Sicherheitslücken in den Produkten, die sich bei Kunden befinden.',
  },
  {
    icon: 'fa-binoculars',
    title: 'Überwachung',
    content: 'Kontinuierliche Überwachung und Analyse der Cyberrisiken.',
  },
  {
    icon: 'fa-users',
    title: 'Expertise',
    content: 'Fehlende Expertise im Bereiche Compliance & Security.',
  },
  {
    icon: 'fa-file-contract',
    title: 'Dokumentation',
    content:
      'Umfassende Dokumentation: Software Bill of Materials, Risikoanalyse, umfassende Betriebsanleitung.',
  },
]

const App = props => {
  const { data } = props
  const contactRef = useRef(null)
  const scrollToBottom = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout>
      <LargeHeroImageText
        title="Cyber Resilience Act"
        subtitle=""
        img={data.hero.childImageSharp}
      >
        Der auf Unternehmen zukommende Cyber Resilience Act (CRA) - als
        Nachfolger des Cyber Security Acts - wird zukünftig deutlich höhere
        Anforderungen in Bezug auf Cybersicherheit an Hersteller stellen, die
        Produkte mit digitalen Elementen in Verkehr bringen.
        <br></br>
        Digitale Elemente sind in diesem Zusammenhang jedes Software- oder
        Hardwareprodukt und seine Datenfernverarbeitungslösungen, einschließlich
        Software- oder Hardwarekomponenten, die separat in Verkehr gebracht
        werden sollen.
      </LargeHeroImageText>
      {/* <ElementSplit headline="Sie möchten weniger lesen und mehr hören?"></ElementSplit>

      <Podcast
        img={data.podcast.childImageSharp}
        imgLink="https://iotusecase.com/podcast/mittelstaendischer-maschinen-und-anlagenbau-das-ist-der-grundstein-fuer-predictive-maintenance/"
      >
        Zusammen mit unserem Partner Elasticsearch und IoT Use Case haben wir
        einen Podcast über Digitalisierung im mittelständischen Maschinenbau
        aufgenommen. Wir sprechen über konkrete Projekte und die ersten Schritte
        in die Richtung Predictive Maintenance.
        <br></br>
        <a href="https://iotusecase.com/podcast/mittelstaendischer-maschinen-und-anlagenbau-das-ist-der-grundstein-fuer-predictive-maintenance/">
          Hier
        </a>{" "}
        kommen Sie zum Podcast.
      </Podcast> */}
      <ElementSplit
        headline="Cyber Resilience Act - Anforderungen"
        subline=""
      ></ElementSplit>

      {/*CRA - Grafik*/}
      <div class="columns is-centered">
        <section class="section">
          <div class="container">
            <figure class="image is-5by3">
              <img src="/img/anforderungen_cra.jpg"></img>
            </figure>
          </div>
        </section>
      </div>

      <TilesBox
        img={data.parallax.childImageSharp}
        tiles={potentialsTiles}
      ></TilesBox>
      <ElementSplit
        headline="Cyber Resilience Act – Ihre Herausforderungen!"
        subline=""
      ></ElementSplit>
      <ListComponent
        img={data.parallax2.childImageSharp}
        items={challenges}
      ></ListComponent>
      <ElementSplit
        headline="Cyber Resilience Act – Unser Service für Sie!"
        subline="colenio ist Ihr kompetenter Partner für den Cyber Resilience Act."
      ></ElementSplit>
      <TilesBox
        img={data.parallax3.childImageSharp}
        tiles={colenioTiles}
      ></TilesBox>
      {/* Dummy ref to enable scroll to view, cf. https://stackoverflow.com/a/41700815*/}
      <div ref={contactRef}></div>
      <ElementSplit
        headline="Unsere Kompetenzen für Sie!"
        subline="Vereinbaren Sie einen ersten und kostenfreien Beratungstermin mit uns."
      ></ElementSplit>
      <Contact
        name="Engineering & Manufacturing"
        mail="sales@colenio.com"
      ></Contact>
      {/* Maybe move CTA into Contact? */}
      {isMobile ? (
        <CallToActionMobile onClick={scrollToBottom} />
      ) : (
        <CallToAction headline="Interesse an einem ersten Beratungstermin?" />
      )}
    </Layout>
  )
}

export default App

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "cybercrime.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax: file(relativePath: { eq: "statistics.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax2: file(relativePath: { eq: "security_check.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax3: file(relativePath: { eq: "formentor.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: NORTH }
        )
      }
    }
    podcast: file(relativePath: { eq: "podcast-iot-use-case.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          aspectRatio: 1.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
